main {
  margin-left: auto;
  margin-right: auto;
  max-width: 50em; /* or adjust as needed */
}

/*three.js*/
canvas {
  position: fixed;
  z-index: -10;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
}

/*images */
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  max-width: 70%;
}

/* Video container */
.video-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 67%;
  position: relative;
  padding-bottom: 41%;
  height: 0;
}

/* Video player */
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*header style */
header {
  font-family: "organetto-variable", sans-serif;
  font-variation-settings: "wdth" 67, "wght" 80;
  font-size: 1em;
  color: #000;
  margin-top: 2em;
  margin-left: auto;
  margin-right: auto;
  max-width: 50em; /* or adjust as needed */
  transition-duration: 50ms;
  transition-property: all;
}

/*header link */
a.header {
  color: #000;
}

/*header hover */
a.header:hover {
  text-decoration: none;
  font-variation-settings: "wdth" 67, "wght" 100;
  transition-duration: 100ms;
  transition-property: all;
}

/*paragraph style */
p {
  font-family: "kepler-3-variable", sans-serif;
  font-variation-settings: "opsz" 7, "wdth" 90, "wght" 280;
  font-size: 1.2em;
  max-width: 38em;
}

/*buttons */
a {
  text-decoration: none;
  transition-duration: 100ms;
  transition-property: all;
}

/*link */
a.link {
  text-decoration: underline;
  transition-duration: 100ms;
  transition-property: all;
  color: #000;
}

/*hover link */
a.link:hover {
  text-decoration: underline;
  color: #fff;
  transition-duration: 100ms;
  transition-property: all;
}

/*link */
a.green {
  text-decoration: underline;
  transition-duration: 100ms;
  transition-property: all;
  color: #fff;
}

/*hover spotify link */
a.green:hover {
  text-decoration: underline;
  color: #3ad972;
  transition-duration: 100ms;
  transition-property: all;
}

/*work item*/
.work-item a {
  text-decoration: none;
  font-family: "halyard-display-variable", sans-serif;
  font-variation-settings: "wght" 100;
  font-size: 3em;
  line-height: 1em;
  color: #000;
}

/*hover work item */
.work-item a:hover {
  font-family: "halyard-display-variable", sans-serif;
  font-variation-settings: "wght" 250;
  color: #ffffff;
}

/*gap between work items */
.work-item {
  margin-bottom: 2em;
}

/*gap underneath */
.bottom-space {
  margin-bottom: 2em;
}

/*footer */
.footer {
  position: absolute;
  bottom: 0;
}

/* Responsive styles */
@media screen and (max-width: 800px) {
  .work-item a {
    font-size: 2.5em;
  }
  h1 {
    font-size: 2em;
    transition-duration: 50ms;
    transition-property: all;
  }
  img {
    max-width: 100%;
  }
  .video-container {
    max-width: 100%;
    padding-bottom: 54%;
  }
}