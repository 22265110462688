main {
  max-width: 50em;
  margin-left: auto;
  margin-right: auto;
}

canvas {
  z-index: -10;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

img {
  width: 100vw;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.video-container {
  max-width: 67%;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 41%;
  display: block;
  position: relative;
}

.video-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

header {
  font-variation-settings: "wdth" 67, "wght" 80;
  color: #000;
  max-width: 50em;
  margin-top: 2em;
  margin-left: auto;
  margin-right: auto;
  font-family: organetto-variable, sans-serif;
  font-size: 1em;
  transition-property: all;
  transition-duration: 50ms;
}

a.header {
  color: #000;
}

a.header:hover {
  font-variation-settings: "wdth" 67, "wght" 100;
  text-decoration: none;
  transition-property: all;
  transition-duration: .1s;
}

p {
  font-variation-settings: "opsz" 7, "wdth" 90, "wght" 280;
  max-width: 38em;
  font-family: kepler-3-variable, sans-serif;
  font-size: 1.2em;
}

a {
  text-decoration: none;
  transition-property: all;
  transition-duration: .1s;
}

a.link {
  color: #000;
  text-decoration: underline;
  transition-property: all;
  transition-duration: .1s;
}

a.link:hover, a.green {
  color: #fff;
  text-decoration: underline;
  transition-property: all;
  transition-duration: .1s;
}

a.green:hover {
  color: #3ad972;
  text-decoration: underline;
  transition-property: all;
  transition-duration: .1s;
}

.work-item a {
  font-variation-settings: "wght" 100;
  color: #000;
  font-family: halyard-display-variable, sans-serif;
  font-size: 3em;
  line-height: 1em;
  text-decoration: none;
}

.work-item a:hover {
  font-variation-settings: "wght" 250;
  color: #fff;
  font-family: halyard-display-variable, sans-serif;
}

.work-item, .bottom-space {
  margin-bottom: 2em;
}

.footer {
  position: absolute;
  bottom: 0;
}

@media screen and (max-width: 800px) {
  .work-item a {
    font-size: 2.5em;
  }

  h1 {
    font-size: 2em;
    transition-property: all;
    transition-duration: 50ms;
  }

  img {
    max-width: 100%;
  }

  .video-container {
    max-width: 100%;
    padding-bottom: 54%;
  }
}

/*# sourceMappingURL=index.861a2b90.css.map */
